const IconSpan = (): JSX.Element => {
	return (
		<span
			style={{
				fontSize: 10,
				verticalAlign: 'top',
			}}
		>
			©
		</span>
	)
}

export default IconSpan
